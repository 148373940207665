import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SidePanel from '../SidePanel/SidePanel'
import AuthNavBar from './AuthNavBar'

import BrokerPanel from './BrokerPanel'
import EmailAccessModal from '../EmailAccessModal/EmailAccessModalMain'

function BrokerInfo() {
	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const [searched, setSearched] = useState(false)

	const [selectedBroker, setSelectedBroker] = useState(null)
	const [isBrokerPanelOpen, setIsBrokerPanelOpen] = useState(false)

	const [showEmailAccessModal, setShowEmailAccessModal] = useState(false)
	const [emailAccounts, setEmailAccounts] = useState([])
	const [selectedEmailAccount, setSelectedEmailAccount] = useState(null)

	const togglePanel = () => {
		setIsPanelOpen(!isPanelOpen)
	}

	const openBrokerPanel = broker => {
		setSelectedBroker(broker)
		setIsBrokerPanelOpen(true)
	}

	const handleSearch = async e => {
		e.preventDefault()
		if (searchTerm.trim().length < 3) {
			setError('Search query must be at least 3 characters long.')
			return
		}
		setIsLoading(true)
		setError(null)
		setSearched(true)

		try {
			const response = await axios.get(
				`/api/brokers/search-broker?query=${searchTerm}`
			)
			setSearchResults(response?.data?.brokers || [])
		} catch (err) {
			setError('An error occurred while searching for brokers.')
			console.error('Search error:', err)
		} finally {
			setIsLoading(false)
		}
	}

	const highlightText = (text, highlight) => {
		if (!highlight.trim() || !text) {
			return <span>{text}</span>
		}
		const regex = new RegExp(`(${highlight})`, 'gi')
		const parts = text.toString().split(regex)
		return (
			<span>
				{parts.filter(String).map((part, i) => {
					return regex.test(part) ? (
						<span key={i} className="bg-yellow-300 text-black">
							{part}
						</span>
					) : (
						<span key={i}>{part}</span>
					)
				})}
			</span>
		)
	}

	const handleEmailAccessRequest = async provider => {
		try {
			const token = localStorage.getItem('jwtToken')

			const response = await axios.post(
				'/api/user/request-email-access',
				{
					provider,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (response.data.authUrl) {
				window.location.href = response.data.authUrl
			} else {
				console.log('Custom email setup or other provider selected')
			}
		} catch (error) {
			console.error('Error requesting email access:', error)
		}
	}

	useEffect(() => {
		const checkEmailPermissions = async () => {
			try {
				const token = localStorage.getItem('jwtToken')

				const response = await axios.get('/api/user/email-permissions', {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})

				if (!response.data.hasEmailAccess) {
					setShowEmailAccessModal(true)
				}

				setEmailAccounts(response.data.emailAccounts)
				if (response.data.emailAccounts.length > 0) {
					setSelectedEmailAccount(response.data.emailAccounts[0])
				}
			} catch (error) {
				console.error('Error checking email permissions:', error)
			}
		}

		checkEmailPermissions()
	}, [])

	return (
		<div className="min-h-screen bg-[#001b2e]">
			<AuthNavBar togglePanel={togglePanel} />

			<div className="border-b border-gray-700"></div>

			<SidePanel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />

			<BrokerPanel
				isOpen={isBrokerPanelOpen}
				onClose={() => setIsBrokerPanelOpen(false)}
				broker={selectedBroker}
			/>

			<main className="pl-0 transition-all duration-300 ease-in-out">
				<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
					<form onSubmit={handleSearch} className="mb-8 flex">
						<input
							type="text"
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							placeholder="Search by name, MC number, or DOT number"
							className="shadow-xl px-4 py-3 rounded-l-md focus:outline-none focus:ring-1 focus:ring-blue-300 bg-gray-800 text-white w-96"
						/>
						<button
							type="submit"
							className="bg-blue-600 text-white px-6 py-3 rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
						>
							Search
						</button>
					</form>

					{isLoading && (
						<p className="text-white text-center text-3xl mt-44">Loading...</p>
					)}
					{error && <p className="text-red-500">{error}</p>}

					{searched && !isLoading && !error && (
						<div className="space-y-6">
							{searchResults.length > 0 ? (
								searchResults.map(broker => (
									<div
										key={broker._id}
										className="bg-[#002b44] rounded-lg shadow-md p-2 mb-2 cursor-pointer"
										onClick={() => openBrokerPanel(broker)}
									>
										<div className="text-xl text-white mb-2">
											<span className="font-medium text-sm mr-2 font-bold">
												{highlightText(broker.companyName, searchTerm)}
											</span>
											<span className="text-xs ml-1 font-normal	">
												MC: {highlightText(broker.mcNumber, searchTerm)}
											</span>{' '}
											<span className="text-xs font-normal	">|</span>
											<span className="text-xs ml-2 font-normal">
												DOT: {highlightText(broker.dotNumber, searchTerm)}
											</span>
											{broker?.domain && (
												<>
													<span className="text-xs font-normal	"> |</span>
													<span className="text-xs ml-2 font-normal">
														DOMAIN: @{broker?.domain?.toUpperCase()}
													</span>
												</>
											)}
										</div>
										<div className="grid grid-cols-2 gap-4">
											<div>
												<p className="text-gray-300 text-xs">
													{broker.address
														? `${broker.address.city || ''}, ${
																broker.address.state || ''
														  } ${broker.address.zipCode || ''}`
														: 'N/A'}
												</p>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="text-white">
									No results found for your search query.
								</p>
							)}
						</div>
					)}
				</div>
			</main>

			<EmailAccessModal
				isOpen={showEmailAccessModal}
				onClose={() => setShowEmailAccessModal(false)}
				onAuthorize={handleEmailAccessRequest}
			/>
		</div>
	)
}

export default BrokerInfo
